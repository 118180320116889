import p5 from 'p5'

class Dot {
  sketch: p5
  x: number
  y: number
  n: number
  size: number
  color: number

  constructor(sketch: p5, x: number, y: number, size: number, c: number) {
    this.sketch = sketch
    this.x = x
    this.y = y
    this.color = c
    this.size = size
    this.n = 0
  }

  update() {
    this.n = this.sketch.noise(
      this.x * 0.008,
      this.y * 0.008,
      this.sketch.frameCount * 0.05,
    )
    this.sketch.push()
    this.sketch.translate(this.x, this.y)
    this.sketch.rotate(this.sketch.TWO_PI * this.n)
    this.sketch.scale(this.n * this.size)
    this.sketch.fill(this.color)
    this.sketch.rect(0, 0, 2.5, 1, 2, 2, 2, 2)
    this.sketch.pop()
  }
}

export const P5Sketch = (sketch: p5) => {
  const IMAGE_SRC = '/images/headshot.png'
  const IMAGE_SCALE = 10
  const DOT_SCALE = 1
  const W = 750
  const H = 750

  let dots: Dot[]
  let dotLength = 0
  let img: p5.Image

  sketch.setup = function setup() {
    sketch.createCanvas(W, H)
    sketch.noiseDetail(2, 0.6)
    sketch.noiseSeed(1000)
    sketch.rectMode(sketch.CENTER)
    sketch.frameRate(9)
    sketch.noStroke()
    sketch.noSmooth()
  }

  function createDots() {
    dots = []
    for (let y = 0; y < img.height; y += 1) {
      if (y >= img.height) break

      for (let x = 0; x < img.width; x += 2) {
        if (x >= img.width) break

        const idx = (y * img.width + x) * 4
        const c = img.pixels[idx]
        const sz = sketch.map(c, 0, 255, 0, IMAGE_SCALE)

        dots.push(
          new Dot(sketch, x * IMAGE_SCALE, y * IMAGE_SCALE, sz * DOT_SCALE, c),
        )
      }
    }

    dotLength = dots.length
  }

  sketch.preload = function preload() {
    img = sketch.loadImage(IMAGE_SRC, (_img) => {
      _img.loadPixels()
      createDots()
    })
  }

  sketch.draw = function draw() {
    sketch.background(17)

    if (dotLength === 0) return

    for (let i = 0; i < dotLength; i++) {
      dots[i].update()
    }
  }
}
