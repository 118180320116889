import React from 'react'
import styled from 'styled-components'
import { FaChevronUp } from 'react-icons/fa'

import { Button } from '../Button'
import { colors } from '~/theme/variables'

const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto ${(p) => p.theme.spacings[3]}rem;
`

export const PageUp = () => {
  return (
    <StyledButton
      variant="chromeless"
      onClick={scrollToTop}
      aria-label="scroll to the top of the page"
    >
      <FaChevronUp
        color={colors.gray400}
        title="scroll to the top of the page"
      />
    </StyledButton>
  )
}
