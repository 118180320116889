import React, { Children, isValidElement, cloneElement } from 'react'
import styled from 'styled-components'

export interface AspectBoxProps {
  ratio?: number
  children?: React.ReactNode
}

const Box = styled.div<AspectBoxProps>`
  position: relative;
  &:before {
    height: 0;
    content: '';
    display: block;
    padding-bottom: ${(p) => p.ratio && (1 / p.ratio) * 100}%;
  }
`

const childStyles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

export const AspectBox: React.FC<AspectBoxProps> = ({
  ratio = 16 / 9,
  children,
  ...rest
}) => {
  const child = Children.only(children) as React.ReactElement<any>
  const isValidChild = isValidElement(child)

  return (
    <Box ratio={ratio} {...rest}>
      {isValidChild && cloneElement(child, { style: childStyles })}
    </Box>
  )
}
