import React, { useState, useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { MdClose } from 'react-icons/md'
import { useMedia } from 'react-use'

import { Logo } from '../Logo'
import { Link } from '../Link'
import { Modal } from '../Modal'
import { Button } from '../Button'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(p) => p.theme.spacings[3]}rem;
`

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: calc(100% - 21px); // 21px comes from close icon

  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    height: auto;
  }
`

const StyledListItem = styled.li`
  margin: 0;
  width: 100%;
  text-align: center;
`

const StyledLink = styled(Link)`
  display: block;
  padding: ${(p) => p.theme.spacings[3]}rem;

  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    display: inline-block;
    padding: ${(p) => p.theme.spacings[1]}rem;
  }
`

const StyledLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Trigger = styled(Button)`
  ${(p) => p.theme.mixins.visuallyHiddenOff}
  padding: 0;
  margin-bottom: 0;

  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    ${(p) => p.theme.mixins.visuallyHidden}
  }
`

const Close = styled(Button)`
  outline: none;
  padding: 0;
  margin: 0;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    display: none;
  }
`

export interface NavProps {
  isOpen: boolean
}

const Menu = () => {
  return (
    <StyledList>
      <StyledListItem>
        <StyledLink to="/">Home</StyledLink>
      </StyledListItem>
      <StyledListItem>
        <StyledLink to="/projects">Work</StyledLink>
      </StyledListItem>
      <StyledListItem>
        <StyledLink to="/posts">Posts</StyledLink>
      </StyledListItem>
      <StyledListItem>
        <StyledLink to="/info">Info</StyledLink>
      </StyledListItem>
      <StyledListItem>
        <StyledLink to="/contact">Contact</StyledLink>
      </StyledListItem>
    </StyledList>
  )
}

export const Header: React.FC = () => {
  const theme = useContext(ThemeContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isLarge = useMedia(`(min-width: ${theme.breakpoints.sm}em)`)

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (isLarge) closeModal()
  }, [isLarge])

  return (
    <StyledHeader>
      <StyledLogoWrapper>
        <Link
          to="/"
          style={{ display: 'block', marginRight: '0.8rem', lineHeight: 0 }}
        >
          <Logo />
        </Link>
      </StyledLogoWrapper>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Navigation Menu"
        fullScreen
      >
        <Close variant="chromeless" onClick={closeModal}>
          <MdClose title="close" />
        </Close>
        <Menu />
      </Modal>

      {!isLarge ? (
        <Trigger variant="chromeless" onClick={openModal}>
          Menu
        </Trigger>
      ) : (
        <Menu />
      )}
    </StyledHeader>
  )
}
