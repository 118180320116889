import React from 'react'
import styled from 'styled-components'

const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100vw;
`

export const Table: React.FC = ({ children, ...rest }) => (
  <TableWrapper {...rest}>
    <table>{children}</table>
  </TableWrapper>
)
