import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'

export interface Images {
  images: {
    edges: {
      node: {
        name: string
        relativePath: string
        childImageSharp: {
          fluid: FluidObject
        }
        publicURL: string
      }
    }[]
  }
}

export const useImages = () => {
  const data: Images = useStaticQuery(graphql`
    query {
      images: allFile(filter: { extension: { in: ["jpg", "png", "gif"] } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
    }
  `)
  return data.images.edges
}
