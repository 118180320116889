import React from 'react'
import styled from 'styled-components'

export interface TimeProps {
  dateTime?: string
  children: string
}

const StyledTime = styled.time`
  font-size: ${(p) => p.theme.dimensions.fontSize.regular}rem;
  font-family: ${(p) => p.theme.fonts.primary};
  font-weight: ${(p) => p.theme.dimensions.fontWeight.normal};
  line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
  color: ${(p) => p.theme.colors.gray300};
  margin-right: 1rem;
  min-width: 110px;
  display: inline-block;
  &:last-child {
    margin-right: 0;
  }
`

export const Time: React.FC<TimeProps> = ({ dateTime, children, ...props }) => {
  return (
    <StyledTime {...props} dateTime={dateTime} itemProp="datePublished">
      {children}
    </StyledTime>
  )
}
