import React, { useState, useEffect } from 'react'

export interface ScrollIndicatorProps {
  height?: string
  bgColor?: string
}

export const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({
  height = '2px',
  bgColor = 'white',
  ...props
}) => {
  const [scroll, setScroll] = useState<number>(0)
  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll: number = document.documentElement.scrollTop
      const windowHeight: number =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      const scroll = `${totalScroll / windowHeight}`
      // @ts-ignore
      setScroll((+scroll * 100).toFixed(1))
    }

    window.addEventListener('scroll', progressBarHandler)

    return () => window.removeEventListener('scroll', progressBarHandler)
  })
  return (
    <div
      style={{
        height: `${height}`,
        backgroundColor: `${bgColor}`,
        position: 'sticky',
        top: '0%',
        left: '0%',
        width: `${scroll}%`,
        transition: 'width 225ms',
        zIndex: 1000,
      }}
      {...props}
    ></div>
  )
}
