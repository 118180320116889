import React from 'react'
import styled, { css } from 'styled-components'

import { widths, ThemeType } from '~/theme/variables'

interface ContainerProps {
  maxWidth?: keyof typeof widths
  marginBottom?: number
  marginTop?: number
}

const defaultMaxWidth = 'xxl'

const getMargin = (
  theme: ThemeType,
  marginBottom?: number,
  marginTop?: number,
) => {
  return css`
    margin-bottom: ${typeof marginBottom !== 'undefined' && marginBottom >= 0
      ? `${theme.spacings[marginBottom]}rem`
      : undefined};

    margin-top: ${typeof marginTop !== 'undefined' && marginTop >= 0
      ? `${theme.spacings[marginTop]}rem`
      : undefined};
  `
}

const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${(p) => p.theme.widths[p.maxWidth || defaultMaxWidth]}px;

  ${(p) => getMargin(p.theme, p.marginBottom, p.marginTop)};
`

export const Container: React.FC<ContainerProps> = ({
  maxWidth,
  marginBottom,
  marginTop,
  children,
}) => (
  <StyledContainer
    className={`container`}
    maxWidth={maxWidth}
    marginBottom={marginBottom}
    marginTop={marginTop}
  >
    {children}
  </StyledContainer>
)
