import type { PrismTheme } from 'prism-react-renderer'
import { colors } from './variables'
import { css } from 'styled-components'

// Null out a theme so we can use CSS instead (to copy pasta better themes)
const theme: PrismTheme = {
  plain: {
    // color: '#282a2e',
    backgroundColor: colors.gray600,
  },
  styles: [
    // {
    //   types: ['comment'],
    //   style: {
    //     color: 'rgb(197, 200, 198)',
    //   },
    // },
    // {
    //   types: ['string', 'number', 'builtin', 'variable'],
    //   style: {
    //     color: 'rgb(150, 152, 150)',
    //   },
    // },
    // {
    //   types: ['class-name', 'function', 'tag', 'attr-name'],
    //   style: {
    //     color: 'rgb(40, 42, 46)',
    //   },
    // },
  ],
}

export const prismThemeCSS = css`
  /**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
  code[class*='language-'],
  pre[class*='language-'] {
    font-family: ${(p) => p.theme.fonts.monospace};
    font-weight: ${(p) => p.theme.dimensions.fontWeight.normal};
    font-size: 1.1rem;
    color: ${(p) => p.theme.colors.gray100};
    background: none;
    text-align: left;
    // word-wrap: normal;
    // white-space: normal !important;
    line-height: 1.5;
    tab-size: 2;
    hyphens: none;
  }

  pre[class*='language-']::-moz-selection,
  pre[class*='language-'] ::-moz-selection,
  code[class*='language-']::-moz-selection,
  code[class*='language-'] ::-moz-selection {
    text-shadow: none;
    background: #383e49;
  }

  pre[class*='language-']::selection,
  pre[class*='language-'] ::selection,
  code[class*='language-']::selection,
  code[class*='language-'] ::selection {
    text-shadow: none;
    background: #9aa2b1;
  }

  /* For regular inline code blocks */
  code {
    padding: 0 6px 1px 6px;
    background: #202020;
    color: ${(p) => p.theme.colors.gray200};
    border-radius: 4px;
    font-weight: 700;
    font-size: 1.25rem;
  }

  @media print {
    code[class*='language-'],
    pre[class*='language-'] {
      text-shadow: none;
    }
  }

  /* Code blocks */
  pre[class*='language-'] {
    padding: 1rem;
    margin-top: ${(p) => p.theme.spacings[4]}rem;
    border-radius: ${(p) => p.theme.borders.radius.lg};
    white-space: pre-wrap !important;
  }

  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: #282c34;
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #5c6370;
  }

  .token.punctuation {
    color: #abb2bf;
  }

  .token.selector,
  .token.tag {
    color: #e06c75;
  }

  .token.property,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.attr-name,
  .token.deleted {
    color: #f09546;
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.builtin,
  .token.inserted {
    color: #adf479;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #56b6c2;
  }

  .token.atrule,
  .token.keyword {
    color: #dd6bff;
  }

  .token.function {
    color: #42aaff;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #dd6bff;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
`

export default theme
