import { useContext } from 'react'

import { GridContext } from '../Grid'

export const useGridContext = () => {
  const store = useContext(GridContext)

  if (!store) {
    throw new Error('useGridContext must be used within a Grid.')
  }

  return store
}

export default useGridContext
