import React from 'react'
import styled from 'styled-components'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
// import theme from 'prism-react-renderer/themes/vsDark'
import theme from '~/theme/prism'

const Line = styled.div`
  display: table-row;
`

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  user-select: none;
  opacity: 0.75;
  padding-right: ${(p) => p.theme.spacings[4]}rem;
  color: ${(p) => p.theme.colors.gray400};
`

const LineContent = styled.span`
  display: table-cell;
`

interface CodeProps {
  className: string
  children: string
}

export const Code: React.FC<CodeProps> = ({ className, children }) => {
  const language = className
    ? (className.replace(/language-/, '') as Language)
    : 'javascript'

  return (
    <Highlight
      {...defaultProps}
      code={children.trim()}
      theme={theme}
      language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <Line key={i} {...getLineProps({ line, key: i })}>
              <LineNo>{i + 1}</LineNo>
              <LineContent>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </LineContent>
            </Line>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
