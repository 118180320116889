import React from 'react'
import styled from 'styled-components'

import { PostEdges } from '~/hooks/usePosts'
import { Link } from '../Link'
import { Time } from '../Time'

export interface PostListProps {
  posts: PostEdges
  numPosts?: number
}

const StyledListItem = styled.li`
  margin-bottom: ${(p) => p.theme.spacings[3]}rem;

  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    margin-bottom: ${(p) => p.theme.spacings[1]}rem;
  }
`

const StyledTime = styled(Time)`
  display: block;
  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    display: inline-block;
  }
`

export const PostList: React.FC<PostListProps> = ({
  posts,
  numPosts,
  ...rest
}) => {
  const postsToMap = numPosts && numPosts > 0 ? posts.slice(0, numPosts) : posts

  return (
    <ul style={{ padding: 0, listStyle: 'none' }} {...rest}>
      {postsToMap.map(({ node }) => {
        const { slug } = node.fields
        const { date, title } = node.frontmatter
        return (
          <StyledListItem key={slug}>
            <StyledTime>{date}</StyledTime>
            <Link to={slug}>{title}</Link>
          </StyledListItem>
        )
      })}
    </ul>
  )
}
