import React from 'react'
import styled from 'styled-components'

import { Typography } from '../Typography'
import { Time } from '../Time'
import { Tag, TagList } from '../Tag'

export interface PostHeaderProps {
  title: string
  date: string
  tags: string[]
}

const StyledHeader = styled.div`
  text-align: center;
  margin-top: 0;
  margin-bottom: ${(p) => p.theme.spacings[5]}rem;

  @media (min-width: ${(p) => p.theme.breakpoints.lg}em) {
    margin: ${(p) => p.theme.spacings[6]}rem 0;
  }
`

const StyledH1 = styled(Typography)`
  margin: ${(p) => p.theme.spacings[3]}rem 0;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    margin: ${(p) => p.theme.spacings[6]}rem 0;
  }
`

export const PostHeader: React.FC<PostHeaderProps> = ({
  title,
  date,
  tags,
}) => {
  return (
    <StyledHeader>
      <TagList style={{ justifyContent: 'center' }}>
        {tags.map((tag) => (
          <Tag key={tag} to={tag}>
            {tag}
          </Tag>
        ))}
      </TagList>
      <StyledH1 variant="displayMd">{title}</StyledH1>
      <Time>{date}</Time>
    </StyledHeader>
  )
}
