import React from 'react'
import { createGlobalStyle } from 'styled-components'
import normalize from './normalize'
import { prismThemeCSS } from './prism'
import noise from './noise'

const BaseStyle = createGlobalStyle`
  ${normalize}
  ${prismThemeCSS}

  html {
    font-size: 16px;
    line-height: 1;
  }

  body {
    background-color: ${(p) => p.theme.colors.pageBackground};
    color: ${(p) => p.theme.colors.white};
    // font-size: ${(p) => p.theme.dimensions.fontSize.regular}rem;
    font-family: ${(p) => p.theme.fonts.primary};
    font-size: ${(p) => p.theme.dimensions.fontSize.regular}rem;
    // line-height: ${(p) => p.theme.dimensions.lineHeight.regular};

    @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
      font-size: ${(p) => p.theme.dimensions.fontSize.large}rem;
    }

    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    &:before {
      ${noise};
    }
  }

  img {
    width: 100%;
    border-radius: ${(p) => p.theme.borders.radius.lg};
  }

  ul {
    margin-top: 0;
  }

  li {
    line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
    p:first-of-type {
      margin-top: 0;
    }
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  a,
  h3 > a,
  p > a,
  li > a {
    color: currentColor;
    transition: color ${(p) => p.theme.animation.durations.fastest} ease;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.075em;

    &:hover {
      color: ${(p) => p.theme.colors.gray300} !important;
      text-decoration: none;
    }

    &:active {
      color: ${(p) => p.theme.colors.primary};
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
    overflow-x: auto;
    border-spacing: 0px;
    border-collapse: collapse;
    margin-top: ${(p) => p.theme.spacings[6]}rem;
    min-width: ${(p) => p.theme.breakpoints.sm - 1}em;

    thead {
      th {
        padding: ${(p) => p.theme.spacings[2]}rem ${(p) =>
  p.theme.spacings[4]}rem;
        text-align: left;
        background-color: ${(p) => p.theme.colors.gray600};
        border-bottom: 1px solid ${(p) => p.theme.colors.faded};
        font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
        font-size: ${(p) => p.theme.dimensions.fontSize.large}rem;
      }

      th:first-of-type {
        width: 25%;
      }
    }

    td {
      padding: ${(p) => p.theme.spacings[4]}rem;
      line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
    }

    tr {
      border-bottom: 1px solid ${(p) => p.theme.colors.faded};
    }
  }
`

export const Global = () => <BaseStyle />
