import React, { lazy, Suspense } from 'react'
import PDFLoading from './PDFLoading'

const LazyBase = lazy(() => import('./PDFViewerBase'))

export interface PDFViewerProps {
  filename: string
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ filename, ...rest }) => {
  const isSSR = typeof window === 'undefined'
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<PDFLoading />}>
          <LazyBase {...rest} filename={filename} />
        </Suspense>
      )}
    </>
  )
}
