import React, { forwardRef, createContext, useEffect, useState } from 'react'
import { Grid as GridBase, IGridProps } from 'styled-css-grid'
import { useWindowSize } from 'react-use'

import { theme } from '~/theme/variables'

const defaultGap = `${theme.grid.gap}px`
export interface GridContextProps {
  columns: string | number | undefined
}

export const GridContext = createContext<GridContextProps | null>(null)

const removeUnitsFromString = (str: string): number => {
  return Number(str.replace(/\%|rem|em|px/gi, ''))
}

export const Grid = forwardRef<HTMLDivElement, IGridProps>(
  ({ rowGap = defaultGap, columnGap = defaultGap, ...rest }, ref) => {
    const { width } = useWindowSize()
    const rawColumnGap = removeUnitsFromString(columnGap)
    const [adjustedColumnGap, setAdjustedColumnGap] = useState(columnGap)

    const gridContext: GridContextProps = {
      columns: rest.columns,
    }

    useEffect(() => {
      // The point at which the grid blows out since the sum of its columnGaps are larger than the viewport itself
      if (width <= 440) {
        setAdjustedColumnGap(`${rawColumnGap / 2}px`)
      } else {
        setAdjustedColumnGap(columnGap)
      }
    }, [width])

    return (
      <GridContext.Provider value={gridContext}>
        <GridBase
          className="grid"
          {...rest}
          ref={ref}
          columnGap={adjustedColumnGap}
          rowGap={rowGap}
        />
      </GridContext.Provider>
    )
  },
)
