import styled, { css } from 'styled-components'

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'chromeless'
  | 'chromelessRaw'
  | 'primarySm'

export interface ButtonProps {
  variant?: ButtonVariants
}

const baseButton = css<ButtonProps>`
  background-color: transparent;
  line-height: 1;
  text-decoration: none;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.gray400};
  border-radius: ${(p) => p.theme.borders.default}px;
  font-family: ${(p) => p.theme.fonts.monospace};
  font-size: ${(p) => p.theme.dimensions.fontSize.regular}rem;
  font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
  padding: ${(p) => p.theme.spacings[2]}rem ${(p) => p.theme.spacings[3]}rem;
  display: inline-block;
  width: auto;
  cursor: pointer;
  transition: all ${(p) => p.theme.animation.durations.fastest} ease;
  &:active,
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: ${(p) => p.theme.colors.gray400};
    color: ${(p) => p.theme.colors.white} !important;
  }
  &[disabled],
  &[disabled]:hover {
    background-color: ${(p) => p.theme.colors.gray100};
    border-color: ${(p) => p.theme.colors.gray100};
    color: ${(p) => p.theme.colors.gray300};
    cursor: default;
  }
`

const variants = {
  primary: css`
    ${baseButton};
  `,
  secondary: css`
    ${baseButton};
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.primary};
  `,
  chromeless: css`
    ${baseButton};
    border: none;
    &:hover {
      background-color: transparent;
    }
  `,
  chromelessRaw: css`
    ${baseButton};
    padding: 0;
    margin: 0;
    border: none;
    &:hover {
      background-color: transparent;
    }
  `,
  primarySm: css`
    ${baseButton};
    padding: 0.75rem 1rem;
    font-size: 1rem;
  `,
}

const getVariant = (variant: ButtonVariants) => {
  return variants[variant]
}

export const Button = styled.button<ButtonProps>`
  ${(p) => getVariant(p.variant || 'primary')};
`
