import React from 'react'
import styled from 'styled-components'

import { AspectBox } from '../AspectBox'
import { Typography } from '../Typography'
export interface VideoProps {
  src: string
  title: string
  ratio: number
}

const StyledWrapper = styled.div`
  margin: ${(p) => p.theme.spacings[3]}rem 0;
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    margin: ${(p) => p.theme.spacings[7]}rem 0;
  }
`

const StyledCaption = styled(Typography)`
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    text-align: right;
  }
`

export const Video: React.FC<VideoProps> = ({
  src,
  title,
  ratio = 1,
  ...rest
}) => {
  return (
    <StyledWrapper>
      <AspectBox ratio={ratio}>
        <iframe
          {...rest}
          src={src}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen
        />
      </AspectBox>
      <StyledCaption variant="h5" color="gray300" marginTop={1}>
        {title}
      </StyledCaption>
    </StyledWrapper>
  )
}
