import React, { useEffect } from 'react'
import styled from 'styled-components'
import p5 from 'p5'

export interface P5WrapperProps {
  sketch: (sketch: p5) => void
  onP5Changed?: (sketch: p5) => void
}

const StyledWrapper = styled.div`
  & > canvas {
    width: 100% !important;
    height: auto !important;
  }
`

export const P5Wrapper: React.FC<P5WrapperProps> = ({
  sketch,
  onP5Changed,
}) => {
  const wrapper: React.RefObject<HTMLDivElement> = React.createRef()

  useEffect(() => {
    const { current } = wrapper
    let canvas: p5

    if (current) {
      if (current.childNodes[0]) {
        current.removeChild(current.childNodes[0])
      }

      canvas = new p5(sketch, current)
      canvas.disableFriendlyErrors = true

      if (onP5Changed) {
        onP5Changed(canvas)
      }
    }

    return () => {
      canvas.remove()
    }
  }, [sketch])

  return <StyledWrapper ref={wrapper} />
}
