import React from 'react'

import { Typography } from '../Typography'

export interface StatisticProps {
  prefix?: string
  suffix?: string
}

export const Statistic: React.FC<StatisticProps> = ({ prefix, suffix }) => {
  return (
    <div>
      <Typography variant="statistic">{prefix}</Typography>
      <Typography variant="h1" tag="span" color="gray300">
        {suffix}
      </Typography>
    </div>
  )
}
