import { css, keyframes } from 'styled-components'

const grain = keyframes`
0%,
to {
    transform: translate(0)
}
10% {
    transform: translate(-5%, -10%)
}
20% {
    transform: translate(-15%, 5%)
}
30% {
    transform: translate(7%, -25%)
}
40% {
    transform: translate(-5%, 25%)
}
50% {
    transform: translate(-15%, 10%)
}
60% {
    transform: translate(15%)
}
70% {
    transform: translateY(15%)
}
80% {
    transform: translate(3%, 35%)
}
90% {
    transform: translate(-10%, 10%)
}
`

const noise = css`
  opacity: 0.18;
  animation: ${grain} 15s steps(15) infinite;
  background-color: ${(p) => p.theme.colors.pageBackground};
  background-image: url(/images/noise.png);
  content: '';
  height: 300%;
  left: -50%;
  position: fixed;
  top: -100%;
  width: 300%;
  pointer-events: none;
  z-index: ${(p) => p.theme.zindex.noise};
`

export default noise
