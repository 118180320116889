import React from 'react'
import { Cell as CellBase, ICellProps } from 'styled-css-grid'
// import { breakpoints } from '~/theme/variables'
import useMedia from '~/hooks/useMedia'
import useGridContext from './useGridContext'

// // Usage example
// <Cell
//   widths={{
//     xs: 12,
//     sm: 4,
//     md: 3,
//     lg: 9,
//   }}
//   offset={{ sm: 4, lg: 3 }}
// >
//   <p>
//     Responsive widths are 🔥
//   </p>
// </Cell>

type Breakpoints = {
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xxl?: number
  xxxl?: number
}

type BreakpointKeys = keyof Breakpoints

type Match = {
  key: BreakpointKeys
  matches: boolean
}

export interface CellProps extends Omit<ICellProps, 'top'> {
  widths?: Breakpoints
  offset?: Breakpoints
  top?: Breakpoints
}

export const Cell: React.FC<CellProps> = ({
  widths = {},
  offset = {},
  top = {},
  children,
  ...rest
}) => {
  // get Column count for this specific grid from the GridContext
  const { columns } = useGridContext()
  const totalColumns =
    columns && Number.isInteger(columns) ? Number(columns) : 0

  // returns an object with boolean values for xs, sm, md, lg, xl, xxl, xxxl matches using
  const dimensions = useMedia()

  const widthMatches = Object.keys(widths)
    .reverse()
    .map(
      (width) =>
        ({
          key: width,
          matches: Boolean(dimensions[width as BreakpointKeys]),
        } as Match),
    )

  // Finds the first media query that matches the widths passed,
  // reserving it means lg down ⬇️, (lg, md, sm, xs)
  const match = widthMatches.find((media) => media.matches)

  // I prefer using an "offset" to be offsets of columns instead of the left behaviour which defaults to "start at" n which is why I've added one to the left value
  return (
    <CellBase
      {...rest}
      width={(match && widths[match.key]) || totalColumns}
      left={match && (offset[match.key] || 0) + 1}
      top={match && top[match.key]}
    >
      {children}
    </CellBase>
  )
}
