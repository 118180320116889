import React from 'react'
import styled from 'styled-components'

const Item = styled.div`
  margin: ${(p) => p.theme.spacings[2]}rem 0;
`

export const BlockList: React.FC = ({ children }) => {
  return (
    <>
      {React.Children.map(children, (child) => (
        <Item>{child}</Item>
      ))}
    </>
  )
}
