import React from 'react'
import styled, { css } from 'styled-components'

import { Typography } from '../Typography'
import { Container } from '../Container'
import { Figure as FigureBase } from '../Figure'
import { Table as TableBase } from '../Table'
import { Link as LinkBase } from '../Link'

const Link = ({ ...rest }) => <LinkBase to={rest.href} {...rest} />
const H1 = ({ ...rest }) => <Typography variant="h1" {...rest} />
const H2 = ({ ...rest }) => (
  <Typography variant="h2" marginTop={7} marginBottom={5} {...rest} />
)
const H3 = ({ ...rest }) => (
  <Typography variant="h3" marginTop={5} marginBottom={0} {...rest} />
)
const H4 = ({ ...rest }) => (
  <Typography variant="h4" marginTop={5} marginBottom={0} {...rest} />
)
const H5 = ({ ...rest }) => (
  <Typography variant="h5" marginBottom={0} {...rest} />
)
const P = ({ ...rest }) => (
  <Typography variant="p" marginTop={3} marginBottom={0} {...rest} />
)
const Blockquote = ({ ...rest }) => (
  <Typography variant="blockquote" {...rest} />
)
const Middle = ({ ...rest }) => <Container maxWidth="md" {...rest} />
const Figure = ({ ...rest }) => <FigureBase {...rest} />
const Table = ({ ...rest }) => <TableBase {...rest} />

const listStyling = css`
  list-style-type: disc;
  background-color: ${(p) => p.theme.colors.gray500};
  padding: ${(p) => p.theme.spacings[3]}rem ${(p) => p.theme.spacings[5]}rem;
  margin-top: ${(p) => p.theme.spacings[3]}rem;
  border-radius: ${(p) => p.theme.borders.radius.lg};
  box-shadow: inset 0 0 1rem ${(p) => p.theme.colors.gray600};
  border: 1px solid ${(p) => p.theme.colors.faded};
  overflow-wrap: break-word;

  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    margin: ${(p) => p.theme.spacings[6]}rem 0;
  }
`

const StyledUL = styled.ul`
  ${listStyling};
`

const StyledOL = styled.ol`
  ${listStyling};
  list-style-type: decimal;
`

const StyledLI = styled.li`
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0;
  }
`

const UL = ({ ...rest }) => <StyledUL {...rest} />
const OL = ({ ...rest }) => <StyledOL {...rest} />
const LI = ({ ...rest }) => <StyledLI {...rest} />

export default {
  Link,
  H1,
  H2,
  H3,
  H4,
  H5,
  P,
  Blockquote,
  Middle,
  Figure,
  UL,
  OL,
  LI,
  Table,
}
