import * as React from 'react'
import styled from 'styled-components'

export interface LogoProps {
  variant?: 'dark' | 'light'
}

const StyledLogo = styled.svg<LogoProps>`
  width: ${(p) => p.theme.heights.logoSmall}px;
  height: ${(p) => p.theme.heights.logoSmall}px;

  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    width: ${(p) => p.theme.heights.logo}px;
    height: ${(p) => p.theme.heights.logo}px;
  }

  circle {
    fill: ${(p) =>
      p.variant === 'dark' ? p.theme.colors.black : p.theme.colors.white};
  }

  path {
    fill: ${(p) =>
      p.variant === 'dark' ? p.theme.colors.white : p.theme.colors.black};
  }
`

export const Logo: React.FC<LogoProps> = ({ variant = 'light' }) => (
  <StyledLogo viewBox="0 0 48 48" variant={variant}>
    <title>Bryan Berger</title>
    <circle cx="24" cy="24" r="24" />
    <path d="M37.597 37.316a1.026 1.026 0 11-2.052 0 1.026 1.026 0 012.052 0M31.808 28.056c-.102 1.35-.45 3.317-1.279 5.1-.738 1.59-2.556 3.52-3.665 3.426-.81-.07-.943-1.111-.964-1.82-.06-2.01.58-4.117 1.23-5.994a40.44 40.44 0 011.544-3.821c.432-.93.85-1.966 1.476-2.787 0 0 1.984 1.59 1.658 5.896m3.847-13.95c-2.26 1.327-3.877 3.048-4.98 4.252-.395.431-1.877 2.337-1.877 2.337s-1.386-.177-2.908.368c.623-.295 2.879-4.326 2.943-7.648.06-3.096-1.169-6.026-3.866-6.083-2.064-.045-4.446 2.802-5.794 5.54a31.392 31.392 0 00-2.022 5.346s-.237-2.945-2.534-4.759c-.601-.474-1.84-1.175-3.258-.759-1.86.546-1.979 2.423-1.559 3.65 0 0 .479-1.33 1.443-1.361 1.82-.06 2.36 3.278 2.35 5.609-.037 8.524 1.391 13.732 3.647 17.12 1.669 2.507 3.99 3.869 4.994 3.184 1.057-.722.624-1.924.624-1.924s-.042.651-.496.867c-.22.105-.738.19-1.106-.048-.958-.615-1.841-1.74-2.371-2.976-1.135-2.648-1.903-5.62-1.898-8.5.011-6.272 2.351-13.11 5.965-18.237.357-.506 1.35-1.715 1.936-.944.137.18.2.404.247.625.163.772.113 1.83.073 2.618-.17 3.328-1.139 6.674-2.893 9.515-.404.655-.6.994-.96 1.358-.401.403-1.062.349-.925-.186-.078.091-.61.684-.122 1.217.286.312.731.227 1.058.015 1.352-.877 2.262-1.595 4.148-2.32 1.767-.68 2.642-.42 2.642-.42-.106.016-.442.7-.506.804-.265.435-.49.9-.721 1.353-.474.928-.92 1.871-1.32 2.833-.8 1.915-1.22 3.29-1.6 5.335-.352 1.894-.46 4.532 1.282 5.734.953.657 2.174.872 3.32.701 1.145-.17 2.216-.707 3.124-1.426 2.139-1.693 3.378-4.416 3.413-7.144.041-3.231-1.53-6.872-4.446-8.459 0 0 .216-.346.511-.738.568-.753 1.644-2.074 2.27-2.664 1.048-.988 1.554-1.464 2.754-2.23 1.55-.988 2.48-1.447 3.62-1.441 1.246.006.568 1.408.568 1.408s1.355-.594.94-1.868c-.145-.45-.603-.854-1.476-.915-1.177-.081-2.826.433-4.234 1.26" />
  </StyledLogo>
)
