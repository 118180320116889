import React from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import { FiExternalLink } from 'react-icons/fi'
import { colors, dimensions } from '~/theme/variables'

// had to exclude ref, see this issue: https://github.com/gatsbyjs/gatsby/issues/16682
export interface LinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  target?: '_blank' | '_self' | '_parent' | '_top'
  showExternal?: boolean
}

export const Link: React.FC<LinkProps> = ({
  to,
  showExternal = true,
  target = '_blank',
  ...props
}) => {
  // If it is an external link, use a anchor tag as recommended, otherwise use local GatsbyLink
  return /^https?:\/\//.test(to || '') ? (
    <>
      <a {...props} href={to} target={target} rel="noopener noreferrer" />{' '}
      {showExternal && (
        <FiExternalLink
          color={colors.gray400}
          size={`${dimensions.fontSize.small}rem`}
          style={{ verticalAlign: '-.125em' }} // fixes vertical alignment with anchor text
        />
      )}
    </>
  ) : (
    <GatsbyLink {...props} to={to} activeClassName="active" />
  )
}
