import React from 'react'
import styled, { css } from 'styled-components'
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs'

import { colors } from '~/theme/variables'

import { Link } from '../Link'
import { Divider } from '../Divider'
import { Typography } from '../Typography'

type Node = {
  fields: {
    slug: string
  }
  frontmatter: {
    title: string
  }
}

export interface PostFooterProps {
  previous?: Node
  next?: Node
}

const StyledFooter = styled.div`
  text-align: center;
  margin-top: ${(p) => p.theme.spacings[5]}rem;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    margin-top: ${(p) => p.theme.spacings[8]}rem;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding-top: ${(p) => p.theme.spacings[5]}rem;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    padding-top: ${(p) => p.theme.spacings[8]}rem;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}em) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const Center = styled.div`
  display: flex;
  align-items: center;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
`

const HideArrow = css`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}em) {
    display: block;
  }
`

const ArrowLeft = styled(BsArrowLeft)`
  ${HideArrow};
`

const ArrowRight = styled(BsArrowRight)`
  ${HideArrow};
`

const PrevWrapper = styled.div`
  margin-bottom: 2rem;

  @media (min-width: ${(p) => p.theme.breakpoints.lg}em) {
    margin-bottom: 0;
  }
`

export const PostFooter: React.FC<PostFooterProps> = ({ previous, next }) => {
  if (previous === null && next === null) return null

  return (
    <StyledFooter>
      <Divider marginBottom={-1} />
      <StyledWrapper>
        {previous && (
          <PrevWrapper>
            <Center>
              <ArrowLeft
                size="2rem"
                style={{ marginRight: '2rem', color: colors.gray400 }}
              />
              <Flex>
                <Typography variant="h5" color="gray300" marginBottom={1}>
                  Prev
                </Typography>
                <Typography tag="span" variant="pl" marginTop={0}>
                  <Link to={previous.fields.slug}>
                    {previous.frontmatter.title}
                  </Link>
                </Typography>
              </Flex>
            </Center>
          </PrevWrapper>
        )}
        {!previous && next && <div style={{ flex: 1 }} />}
        {next && (
          <div>
            <Center>
              <Flex>
                <Typography variant="h5" color="gray300" marginBottom={1}>
                  Next
                </Typography>
                <Typography tag="span" variant="pl" marginTop={0}>
                  <Link to={next.fields.slug}>{next.frontmatter.title}</Link>
                </Typography>
              </Flex>
              <ArrowRight
                size="2rem"
                style={{ marginLeft: '2rem', color: colors.gray400 }}
              />
            </Center>
          </div>
        )}
      </StyledWrapper>
    </StyledFooter>
  )
}
