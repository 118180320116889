import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface PDFLoadingProps {
  minHeight?: number
}

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

const Box = styled.div<PDFLoadingProps>`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: ${(p) => p.minHeight}px;
  background-color: ${(p) => p.theme.colors.faded};
  padding: ${(p) => p.theme.spacings[4]}rem;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const PulseText = styled.p`
  animation: ${pulse} 0.75s ease infinite;
`

const PDFLoading: React.FC<PDFLoadingProps> = ({ minHeight = 0, ...rest }) => {
  return (
    <Box {...rest} minHeight={minHeight}>
      <PulseText>Loading...</PulseText>
    </Box>
  )
}

export default PDFLoading
