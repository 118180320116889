import React from 'react'
import styled from 'styled-components'

import { Time } from '../Time'
import { Typography } from '../Typography'
import { Link } from '../Link'
import { TagList } from '../Tag'
import { Image } from '../Image'

export interface ProjectCardProps {
  date: string
  image: string
  title: string
  slug: string
  tags?: React.ReactNode
}

const StyledMeta = styled.div`
  margin-top: ${(p) => p.theme.spacings[2]}rem;
`

export const ProjectCard: React.FC<ProjectCardProps> = ({
  date,
  image,
  title,
  slug,
  tags,
  ...props
}) => {
  return (
    <div {...props}>
      <Link to={slug}>
        <Image src={image} alt={title} />
      </Link>
      <StyledMeta>
        <Time>{date}</Time>
        <Typography variant="h3" marginBottom={3} marginTop={2}>
          <Link to={slug}>{title}</Link>
        </Typography>
        {tags && <TagList>{tags}</TagList>}
      </StyledMeta>
    </div>
  )
}
