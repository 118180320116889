import React from 'react'
import styled from 'styled-components'
import {
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaGithub,
  FaInstagram,
  FaCodepen,
  FaCalendar,
} from 'react-icons/fa'
import { IconType } from 'react-icons/lib/cjs'

import { Container } from '../Container'
import { Typography } from '../Typography'
import { Divider } from '../Divider'
import { Link } from '../Link'
import { PageUp } from '../PageUp'
import { dimensions } from '~/theme/variables'

export interface FooterProps {
  author: {
    name: string
    resume: string
  }
  social: {
    twitter: string
    github: string
    linkedin: string
    instagram: string
    codepen: string
    calendar: string
  }
}

const iconMap = {
  twitter: FaTwitter,
  facebook: FaFacebook,
  instagram: FaInstagram,
  linkedin: FaLinkedin,
  github: FaGithub,
  codepen: FaCodepen,
  calendar: FaCalendar,
}

const StyledFooter = styled.footer`
  display: grid;
  width: 100%;
  align-items: start;
  padding-bottom: ${(p) => p.theme.spacings[3]}rem;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: ${(p) => p.theme.grid.gap}px;
  grid-row-gap: ${(p) => p.theme.grid.gap}px;
  grid-template-columns: 1fr;

  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const StyledSocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / span 1;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    grid-column: 3 / span 1;
  }
`

const StyledSocialIcon = styled.div`
  padding-left: 0.5rem;
`

const StyledCopyrightWrapper = styled.div`
  grid-column: 1 / span 1;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    grid-column: 1 / span 3;
  }
`

const StyledUL = styled.ul`
  padding: 0;
  list-style: none;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    grid-column: 1 / span 2;
  }
`

const Icon = styled.div<IconType>``

export const Footer: React.FC<FooterProps> = ({ social, author }) => (
  <Container>
    <Divider marginTop={0} />
    <StyledFooter>
      <StyledUL>
        <li>
          <Link to="/tags">Tag Archive</Link>
        </li>
        <li>
          <Link to={author.resume}>Resume (pdf)</Link>
        </li>
        <li>
          <Link to={social.calendar}>Book Calendar Time</Link>
        </li>
      </StyledUL>
      <StyledSocialIconsWrapper>
        {(Object.keys(social) as Array<keyof typeof social>).map((key) => (
          <StyledSocialIcon key={key}>
            <Link to={social[key]} target="_blank" showExternal={false}>
              <Icon
                name={key}
                as={iconMap[key]}
                title={key}
                size={`${dimensions.fontSize.large}rem`}
              />
            </Link>
          </StyledSocialIcon>
        ))}
      </StyledSocialIconsWrapper>
      <StyledCopyrightWrapper>
        <Typography variant="h5" color="gray400">
          &copy;{`${new Date().getFullYear()} ${author.name}`} <br />
          custom Gatsby theme written in Typescript
        </Typography>
      </StyledCopyrightWrapper>
    </StyledFooter>
    <PageUp />
  </Container>
)
