import React from 'react'
import styled from 'styled-components'

// import Figure from '../Figure'
import { Typography } from '../Typography'
import { Divider } from '../Divider'
import { Grid, Cell } from '../Grid'
import { Link } from '../Link'
import { Tag, TagList } from '../Tag'
import { Container } from '../Container'

export interface ProjectHeaderProps {
  title: string
  overview: string
  role: string
  tags: string[]
  liveUrl?: string
}

const StyledCell = styled(Cell)`
  @media (min-width: ${(p) => p.theme.breakpoints.xl}em) {
    margin: 0 auto;
  }
`

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  title,
  overview,
  role,
  liveUrl,
  tags,
}) => {
  return (
    <>
      <Container>
        <Typography
          variant="display"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Grid columns={`repeat(auto-fit, minmax(264px, 1fr))`}>
          <StyledCell>
            <Typography variant="h5" marginBottom={1}>
              Overview
            </Typography>
            <Typography marginBottom={0}>{overview}</Typography>
          </StyledCell>
          <StyledCell>
            <Typography variant="h5" marginBottom={1}>
              Filed Under
            </Typography>
            <TagList>
              {tags.map((tag) => (
                <Tag key={tag} to={tag}>
                  {tag}
                </Tag>
              ))}
            </TagList>
          </StyledCell>
          <StyledCell>
            <Typography variant="h5" marginBottom={1}>
              Role(s)
            </Typography>
            <Typography marginBottom={0}>{role}</Typography>
          </StyledCell>
          {liveUrl && (
            <StyledCell>
              <Typography variant="h5" marginBottom={1}>
                Live Site
              </Typography>
              <Typography marginBottom={0}>
                <Link to={liveUrl}>Visit</Link>
              </Typography>
            </StyledCell>
          )}
        </Grid>
        <Divider marginTop={8} marginBottom={8} />
      </Container>
    </>
  )
}
