import React from 'react'
import styled from 'styled-components'
import slugify from 'slugify'

import { Link, LinkProps } from '../Link'

const StyledTagLink = styled(Link)`
  text-decoration: none;
  padding: 6px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.borders.large}px;
  font-family: ${(p) => p.theme.fonts.monospace};
  font-size: ${(p) => p.theme.dimensions.fontSize.small}rem;
  font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
  background-color: ${(p) => p.theme.colors.tag};
  margin-right: ${(p) => p.theme.spacings[1]}rem;
  margin-bottom: ${(p) => p.theme.spacings[1]}rem;
  &:hover {
    color: ${(p) => p.theme.colors.gray300};
  }
  &:last-child {
    margin-right: 0;
  }
`

export const Tag: React.FC<LinkProps> = ({ to, ...props }) => (
  <StyledTagLink to={`/tags/${slugify(to)}`} {...props} />
)
