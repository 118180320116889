import styled from 'styled-components'

export interface DividerProps {
  marginTop?: number
  marginBottom?: number
  marginTopSm?: number
  marginBottomSm?: number
}

export const Divider = styled.hr<DividerProps>`
  margin-top: ${(p) =>
    p.marginTopSm && p.marginTopSm >= 0
      ? `${p.theme.spacings[p.marginTopSm]}rem`
      : 0};
  margin-bottom: ${(p) =>
    p.marginBottomSm && p.marginBottomSm >= 0
      ? `${p.theme.spacings[p.marginBottomSm]}rem`
      : 0};

  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    margin-top: ${(p) =>
      p.marginTop && p.marginTop >= 0
        ? `${p.theme.spacings[p.marginTop]}rem`
        : 0};
    margin-bottom: ${(p) =>
      p.marginBottom && p.marginBottom >= 0
        ? `${p.theme.spacings[p.marginBottom]}rem`
        : 0};
  }

  border: 0;
  background-color: ${(p) => p.theme.colors.divider};
  height: 1px;
  text-align: left;
  width: 100%;
`

Divider.defaultProps = {
  marginTop: 8,
  marginBottom: 8,
  marginTopSm: 6,
  marginBottomSm: 6,
}
