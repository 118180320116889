import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

export interface SEOProps {
  title?: string
  description?: string
  keywords?: string
  image?: string
  path?: string
  article?: boolean
  noIndex?: boolean
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      defaultTitle: string
      defaultDescription: string
      defaultKeywords: string
      defaultImage: string
      titleTemplate: string
      siteUrl: string
      author: {
        slug: string
      }
    }
  }
}

export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  keywords,
  image,
  path,
  article = true,
  noIndex = false,
}) => (
  <StaticQuery
    query={query}
    render={(data: StaticQueryProps) => {
      const {
        defaultTitle,
        defaultDescription,
        defaultKeywords,
        defaultImage,
        siteUrl,
        titleTemplate,
        author,
      } = data.site.siteMetadata

      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        keywords: keywords || defaultKeywords,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${path}` || siteUrl,
        creator: `@${author.slug}`,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            {(noIndex ? true : null) && (
              <meta property="robots" content="noindex,nofollow" />
            )}

            <meta name="keywords" content={seo.keywords} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            {seo.image && <meta name="twitter:card" content={seo.image} />}
            {seo.creator && (
              <meta name="twitter:creator" content={seo.creator} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultKeywords: keywords
        defaultImage: image
        titleTemplate
        siteUrl
        author {
          slug
        }
      }
    }
  }
`
