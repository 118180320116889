import React from 'react'
import styled, { css } from 'styled-components'

import { AspectBox } from '../AspectBox'
import { Typography } from '../Typography'

export interface LocalVideoProps {
  src: string
  title: string
  ratio: number
  noBorder?: boolean
}

const borderStyles = css`
  background-color: ${(p) => p.theme.colors.faded};
  padding: ${(p) => p.theme.spacings[3]}rem;
  border-radius: ${(p) => p.theme.borders.radius.lg};
`

const StyledWrapper = styled.div<{ noBorder: boolean }>`
  ${(p) => !p.noBorder && borderStyles};
`

const StyledCaption = styled(Typography)`
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    text-align: right;
  }
`

export const LocalVideo: React.FC<LocalVideoProps> = ({
  src,
  title,
  ratio = 16 / 9,
  noBorder = false,
  ...rest
}) => {
  return (
    <div>
      <StyledWrapper noBorder={noBorder}>
        <AspectBox ratio={ratio}>
          <video {...rest} title={title} autoPlay controls loop>
            <source src={src} type="video/mp4" />
          </video>
        </AspectBox>
      </StyledWrapper>
      <StyledCaption variant="h5" color="gray300" marginTop={1}>
        {title}
      </StyledCaption>
    </div>
  )
}
