import React from 'react'
import styled from 'styled-components'

const StyledPage = styled.div`
  display: block;
  flex: 1;
  position: relative;

  padding: ${(p) => p.theme.spacings[5]}rem 0;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    padding: ${(p) => p.theme.spacings[8]}rem 0;
  }
`

interface PageProps {
  className?: string
}

export const Page: React.FC<PageProps> = ({ children, className }) => (
  <StyledPage className={className}>{children}</StyledPage>
)
