import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { Link } from '../Link'
import { Typography } from '../Typography'
import { Image, ImageProps } from '../Image'

export interface FigureProps extends ImageProps {
  caption?: string
  href?: string
}

type Ref = HTMLElement

const StyledFigure = styled.figure`
  margin: ${(p) => p.theme.spacings[3]}rem 0;
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    margin: ${(p) => p.theme.spacings[7]}rem 0;
  }
`

const StyledCaption = styled(Typography)`
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    text-align: right;
  }
`

interface LinkWrapperProps {
  href?: string
  children: React.ReactNode
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({ href, children }) => {
  return href ? (
    <Link to={href} showExternal={false} style={{ display: 'block' }}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

export const Figure: React.FC<FigureProps> = forwardRef<Ref, FigureProps>(
  ({ src, caption, alt, href, ...rest }, ref) => {
    return (
      <StyledFigure ref={ref} {...rest}>
        <LinkWrapper href={href}>
          <Image src={src} alt={alt} />
        </LinkWrapper>
        <figcaption>
          <StyledCaption variant="h5" color="gray300" marginTop={1}>
            {caption}
          </StyledCaption>
        </figcaption>
      </StyledFigure>
    )
  },
)
