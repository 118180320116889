import React from 'react'
import styled, { css } from 'styled-components'

import { ThemeType } from '~/theme/variables'

export type TypographyVariants = keyof typeof variants
export type TypographyTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span'
  | 'blockquote'
export type TypographyColors = keyof ThemeType['colors']

export interface TypographyProps
  extends React.ComponentPropsWithoutRef<'span'> {
  variant?: TypographyVariants
  tag?: TypographyTags
  color?: TypographyColors
  marginBottom?: number
  marginTop?: number
  style?: React.CSSProperties
}

const defaultVariantMapping = {
  display: 'h1',
  displayMd: 'h1',
  statistic: 'span',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  pl: 'p',
  psm: 'p',
  div: 'div',
  span: 'span',
  blockquote: 'blockquote',
}

const defaultColor = 'white'
const defaultVariant = 'p'

const HeadingStyle = css`
  font-family: ${(p) => p.theme.fonts.primary};
  line-height: ${(p) => p.theme.dimensions.lineHeight.heading};
  margin: 0;
`

const variants = {
  display: css`
    ${HeadingStyle};
    font-size: ${(p) => p.theme.dimensions.headingSizes.displaySm}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
    margin-bottom: ${(p) => p.theme.spacings[7]}rem;

    @media (min-width: ${(p) => p.theme.breakpoints.xl}em) {
      font-size: ${(p) => p.theme.dimensions.headingSizes.display}rem;
    }
  `,
  displayMd: css`
    ${HeadingStyle};
    font-size: ${(p) => p.theme.dimensions.headingSizes.displaySm}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
    margin-bottom: ${(p) => p.theme.spacings[7]}rem;

    @media (min-width: ${(p) => p.theme.breakpoints.xl}em) {
      font-size: ${(p) => p.theme.dimensions.headingSizes.displayMd}rem;
    }
  `,
  statistic: css`
    ${HeadingStyle};
    font-size: ${(p) => p.theme.dimensions.headingSizes.displaySm}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};

    @media (min-width: ${(p) => p.theme.breakpoints.xl}em) {
      font-size: ${(p) => p.theme.dimensions.headingSizes.display * 1.5}rem;
    }
  `,
  h1: css`
    ${HeadingStyle};
    font-size: ${(p) => p.theme.dimensions.headingSizes.h1}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
    margin-bottom: ${(p) => p.theme.spacings[7]}rem;
  `,
  h2: css`
    ${HeadingStyle};
    font-size: ${(p) => p.theme.dimensions.headingSizes.h2}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
    margin-bottom: ${(p) => p.theme.spacings[4]}rem;
  `,
  h3: css`
    ${HeadingStyle};
    font-size: ${(p) => p.theme.dimensions.headingSizes.h3}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
  `,
  h4: css`
    font-family: ${(p) => p.theme.fonts.monospace};
    font-size: ${(p) => p.theme.dimensions.headingSizes.h4}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
    line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
  `,
  h5: css`
    font-family: ${(p) => p.theme.fonts.monospace};
    font-size: ${(p) => p.theme.dimensions.headingSizes.h5}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
    line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
    text-transform: uppercase;
    margin: 0;
  `,
  span: css``,
  p: css`
    margin-bottom: ${(p) => p.theme.spacings[4]}rem;
    line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
  `,
  pl: css`
    font-size: ${(p) => p.theme.dimensions.fontSize.large}rem;
  `,
  psm: css`
    font-size: ${(p) => p.theme.dimensions.fontSize.base}rem;
    line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
  `,
  blockquote: css`
    font-family: ${(p) => p.theme.fonts.monospace};
    font-size: ${(p) => p.theme.dimensions.headingSizes.h3}rem;
    font-weight: ${(p) => p.theme.dimensions.fontWeight.normal};
    line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
    margin: ${(p) => p.theme.spacings[6]}rem 0;
    border-left: 2px solid ${(p) => p.theme.colors.white};
    padding-left: 2rem;
    letter-spacing: -1px;

    & > p {
      font-size: inherit;
    }

    @media (min-width: ${(p) => p.theme.breakpoints.xl}em) {
      font-size: ${(p) => p.theme.dimensions.headingSizes.h2}rem;
    }
  `,
}

const getVariant = (variant: TypographyVariants) => {
  return variants[variant]
}

const getMargin = (
  theme: ThemeType,
  marginBottom?: number,
  marginTop?: number,
) => {
  return css`
    margin-bottom: ${typeof marginBottom !== 'undefined' && marginBottom >= 0
      ? `${theme.spacings[marginBottom]}rem`
      : undefined};

    margin-top: ${typeof marginTop !== 'undefined' && marginTop >= 0
      ? `${theme.spacings[marginTop]}rem`
      : undefined};
  `
}

const StyledText = styled.div<TypographyProps>`
  ${(p) => p.variant && getVariant(p.variant)};
  ${(p) => getMargin(p.theme, p.marginBottom, p.marginTop)};

  color: ${(p) => p.theme.colors[p.color || defaultColor]};
`

export const Typography: React.FC<TypographyProps> = ({
  tag,
  variant = defaultVariant,
  color = defaultColor,
  marginBottom,
  marginTop,
  children,
  ...rest
}) => {
  const AsComponent: TypographyTags =
    tag ||
    (defaultVariantMapping[variant as TypographyVariants] as TypographyTags)
  return (
    <StyledText
      {...rest}
      color={color}
      marginBottom={marginBottom}
      marginTop={marginTop}
      variant={variant}
      as={AsComponent}
    >
      {children}
    </StyledText>
  )
}
