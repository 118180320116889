import React, { useContext } from 'react'
import Img from 'gatsby-image'
import { useImages } from '~/hooks/useImages'
import { ThemeContext } from 'styled-components'

export interface ImageProps {
  src: string
  alt: string
}

export const Image: React.FC<ImageProps> = ({ src, alt }) => {
  const images = useImages()
  const { colors } = useContext(ThemeContext)

  const image = images.find((edge) => {
    return edge.node.relativePath.includes(src)
  })

  if (!image) {
    return null
  }

  // Must be a .gif
  if (image.node.childImageSharp === null) {
    return <img alt={alt} src={image.node.publicURL} />
  }

  return (
    <Img
      durationFadeIn={300}
      backgroundColor={colors.faded}
      alt={alt}
      fluid={image.node.childImageSharp.fluid}
    />
  )
}

/**
 * TODO: remove the need to use the useImages hook here, and pass in the proper URL directly (create a File directive on the frontmatter, like we did for Cover)
 */
