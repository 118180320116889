import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

interface Props extends ReactModal.Props {
  className?: string
  fullScreen?: boolean
}

const ReactModalAdapter: React.SFC<Props> = ({
  className,
  fullScreen,
  ...rest
}: Props) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  const bodyClassName = `${className}__body`
  return (
    <ReactModal
      closeTimeoutMS={400}
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      bodyOpenClassName={bodyClassName}
      {...rest}
    />
  )
}

export const Modal = styled(ReactModalAdapter)`
  &__body {
    overflow: hidden;
  }
  &__overlay {
    z-index: ${(p) => p.theme.zindex.modals};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 400ms;
    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }
  &__content {
    outline: none;
    background: ${(p) => p.theme.colors.pageBackground};
    padding: ${(p) => p.theme.spacings[4]}rem;
    border-radius: ${(p) =>
      p.fullScreen ? '0' : `${p.theme.borders.large}px`};
    width: ${(p) => (p.fullScreen ? '100%' : undefined)};
    height: ${(p) => (p.fullScreen ? '100%' : undefined)};
    margin: ${(p) => (p.fullScreen ? 0 : `${p.theme.spacings[4]}rem`)};
    -webkit-overflow-scrolling: touch;
    &.ReactModal__Content--after-open {
    }
    &.ReactModal__Content--before-close {
    }
  }
`
