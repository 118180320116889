import React from 'react'
import styled from 'styled-components'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  min-height: 40em;
  padding: ${(p) => p.theme.spacings[5]}rem 0;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}em) {
    padding: ${(p) => p.theme.spacings[8]}rem 0;
  }
`

export const ProjectContent: React.FC = ({ children, ...rest }) => {
  return <StyledContent {...rest}>{children}</StyledContent>
}
