import { useEffect, useState } from 'react'
import { useMedia as useMediaBase } from 'react-use'

import { breakpoints } from '~/theme/variables'

const defaultState = {
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  xxxl: false,
}

const useMedia = () => {
  const [state, setState] = useState(defaultState)

  const sm = useMediaBase(`(min-width: ${breakpoints.sm}em)`)
  const md = useMediaBase(`(min-width: ${breakpoints.md}em)`)
  const lg = useMediaBase(`(min-width:${breakpoints.lg}em)`)
  const xl = useMediaBase(`(min-width: ${breakpoints.xl}em)`)
  const xxl = useMediaBase(`(min-width: ${breakpoints.xxl}em)`)
  const xxxl = useMediaBase(`(min-width: ${breakpoints.xxxl}em)`)

  useEffect(() => {
    setState({
      sm,
      md,
      lg,
      xl,
      xxl,
      xxxl,
    })
  }, [sm, md, lg, xl, xxl, xxxl])

  return state
}

export default useMedia
