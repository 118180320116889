import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import ReactModal from 'react-modal'

import {
  Header,
  Footer,
  Markdown,
  AspectBox,
  Grid,
  Cell,
  Typography,
  Statistic,
  Container,
  Code,
  Video,
  PDFViewer,
  ScrollIndicator,
  LocalVideo,
  Divider,
} from '~/components'
import { Global } from '~/theme/global'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
      author: {
        name: string
        resume: string
      }
      social: {
        twitter: string
        github: string
        linkedin: string
        instagram: string
        codepen: string
        calendar: string
      }
    }
  }
}

const components = {
  a: Markdown.Link,
  h1: Markdown.H1,
  h2: Markdown.H2,
  h3: Markdown.H3,
  h4: Markdown.H4,
  h5: Markdown.H5,
  p: Markdown.P,
  ol: Markdown.OL,
  ul: Markdown.UL,
  li: Markdown.LI,
  table: Markdown.Table,
  blockquote: Markdown.Blockquote,
  Figure: Markdown.Figure,
  Middle: Markdown.Middle,
  Blockquote: Markdown.Blockquote,
  code: Code,
  AspectBox,
  Statistic,
  Grid,
  Cell,
  Typography,
  Container,
  Video,
  PDFViewer,
  LocalVideo,
  Divider,
  // inlineCode: Code
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${(p) => p.theme.dimensions.containerPadding}rem;
  padding-right: ${(p) => p.theme.dimensions.containerPadding}rem;
`

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
            author {
              name
              resume
            }
            social {
              twitter
              github
              linkedin
              instagram
              codepen
              calendar
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      const {
        title,
        description,
        keywords,
        author,
        social,
      } = data.site.siteMetadata
      ReactModal.setAppElement('#___gatsby')
      return (
        <StyledRoot>
          <ScrollIndicator />
          <StyledMain>
            <Global />
            <Helmet
              title={title}
              meta={[
                {
                  name: 'description',
                  content: description,
                },
                { name: 'keywords', content: keywords },
              ]}
            >
              <link
                href="//fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Code+Pro:400,700&swap=true"
                rel="stylesheet"
              />
            </Helmet>
            <Header />
            <MDXProvider components={components}>{children}</MDXProvider>
            <Footer social={social} author={author} />
          </StyledMain>
        </StyledRoot>
      )
    }}
  />
)

export default IndexLayout
